import { MapObjectProperties } from "@iventis/map-types/src/map-object-properties";
import { ExtractUpdateEntry, UndoRedoEntry } from "@iventis/utilities/src/undo-redo/undo-redo-v2";
import isEqual from "lodash.isequal";
import { TypedFeature } from "../types/internal";

/** The map object properties which undo/redo can support */
export const undoableMapObjectProperties: (keyof MapObjectProperties)[] = ["rotation", "level", "selection", "modeOfTransport"];

export interface PartialFeature extends Partial<Omit<TypedFeature, "properties">> {
    properties?: Partial<MapObjectProperties>;
}

/** Takes the previous state of feature and the next state of the feature and records a change with only the properties which have changed */
export function extractChangeInGeoJson(
    previous: PartialFeature,
    next: PartialFeature,
    getUndoableProperties: () => (keyof MapObjectProperties)[]
): ExtractUpdateEntry<UndoRedoEntry<PartialFeature>>["change"][number] | undefined {
    if (!previous || !next) {
        return undefined;
    }
    const change: Record<"from" | "to", PartialFeature> = {
        from: {},
        to: {},
    };

    const hasGeometryChanged = !isEqual(previous?.geometry, next.geometry);
    if (hasGeometryChanged) {
        // Set the geometry changes
        change.from.geometry = previous?.geometry;
        change.to.geometry = next.geometry;
    }
    getUndoableProperties().forEach((property) => {
        const hasPropertyChanged = !isEqual(previous?.properties?.[property], next?.properties?.[property]);
        if (hasPropertyChanged) {
            change.from.properties = change.from.properties || {};
            change.to.properties = change.to.properties || {};
            change.from.properties[property] = previous?.properties?.[property];
            change.to.properties[property] = next?.properties?.[property];
        }
    });
    return change;
}
