import { BehaviorSubject } from "rxjs";

/**
 * An undo/redo entry
 * @param TFromPartial The partial type of an item. This is used for updates, where it is assigned to both the "from" and "to" properties
 * @param TFrom The full type of the item. This is used for creations and deletions, where it is assigned to either the "from" or "to" property, depending on whether we are creating or deleting
 * @example
 * type TEntry = UndoRedoChange<{ id: string; name?: string; email?: string }, { id: string; name: string; email: string }>;
 *
 * // Update
 * const updateEntry: TEntry = {
 *  operation: "update",
 *  change: [{ from: { id: "1", name: "Bar" }, to: { id: "1", name: "Baz" } }]
 * };
 * // Create
 * const createEntry: TEntry = {
 *  operation: "create",
 *  items: [{ id: "1", name: "Bar", email: "foo@bar.com" }]
 * };
 * // Delete
 * const deleteEntry: TEntry = {
 *  operation: "delete",
 *  items: [{ id: "1", name: "Bar", email: "foo@bar.com" }]
 * };
 * // Type error - missing property "email"
 * const invalidEntry: TEntry = {
 *  operation: "delete",
 *  items: [{ id: "1", name: "Bar" }]
 * };
 */
export type UndoRedoEntry<TItemPartial extends unknown, TItem extends TItemPartial = TItemPartial> =
    | {
          change: { from: TItemPartial; to: TItemPartial }[];
          operation: "update";
      }
    | {
          items: TItem[];
          operation: "create" | "delete";
      };

export type ExtractRestorableEntry<TEntry extends UndoRedoEntry<unknown>> = TEntry extends { operation: infer U } ? (U extends "create" | "delete" ? TEntry : never) : never;
export type ExtractUpdateEntry<TEntry extends UndoRedoEntry<unknown>> = TEntry extends { operation: infer U } ? (U extends "update" ? TEntry : never) : never;

/**
 * A class to manage undo and redo operations
 * @param TItemPartial The partial type of the items (Required)
 * @param TItem The full type of the items (Optional - extends TItemPartial)
 * @param TEntry The type of an undo/redo entry (Optional - extends UndoRedoEntry<TItemPartial, TItem>)
 * @example
 * const undoRedo = new UndoRedo<{ id: string; name?: string }, { id: string; name: string }>();
 */
export class UndoRedoV2<
    TItemPartial extends unknown,
    TItem extends TItemPartial = TItemPartial,
    TEntry extends UndoRedoEntry<TItemPartial, TItem> = UndoRedoEntry<TItemPartial, TItem>
> {
    private undoStackSubject$: BehaviorSubject<TEntry[]> = new BehaviorSubject<TEntry[]>([]);

    private redoStackSubject$: BehaviorSubject<TEntry[]> = new BehaviorSubject<TEntry[]>([]);

    private deleteHandler: ((entry: ExtractRestorableEntry<TEntry>) => ExtractRestorableEntry<TEntry>["items"] | undefined) | undefined;

    private restoreHandler: ((entry: ExtractRestorableEntry<TEntry>) => void) | undefined;

    private updateHandler: ((entry: ExtractUpdateEntry<TEntry>) => void) | undefined;

    // eslint-disable-next-line no-empty-function
    constructor(private _id?: string, private readonly maxStackSize = 50) {}

    /** Id of the undo/redo instance. Useful for debugging */
    get id() {
        return this._id;
    }

    get canUndo(): boolean {
        return this.undoStack$.value.length > 0;
    }

    get canRedo(): boolean {
        return this.redoStack$.value.length > 0;
    }

    // Read only stream of the undo stack
    get undoStack$(): Pick<BehaviorSubject<TEntry[]>, "value" | "subscribe"> {
        return this.undoStackSubject$;
    }

    // Read only stream of the redo stack
    get redoStack$(): Pick<BehaviorSubject<TEntry[]>, "value" | "subscribe"> {
        return this.redoStackSubject$;
    }

    /**
     * Adds a change to the undo stack
     * @param from An undo/redo entry
     * @example
     * undoRedo.push({ change: [{ from: "missing", to: { id: "1", name: "Bar", email: "foo@bar.com" }] }); // Create
     * undoRedo.push({ change: [{ from: { id: "1", name: "Bar" }, to: { id: "1", name: "Baz" } }] }); // Update
     * undoRedo.push({ change: [{ from: { id: "1", name: "Baz", email: "foo@baz.com" }, to: "missing" }] }); // Delete
     * undoRedo.push({ change: [{ from: { id: "1", name: "Baz" }, to: "missing" }] }); // Type Error - missing property "name"
     */
    public push(entry: TEntry): void {
        this.undoStackSubject$.next([...this.undoStack$.value, entry]);
        this.redoStackSubject$.next([]);
        if (this.undoStack$.value.length > this.maxStackSize) {
            this.undoStackSubject$.next(this.undoStack$.value.slice(1));
        }
    }

    /**
     * Undoes the last change
     * @returns The entry of the change which has been undone
     */
    public undo() {
        // Get the last change in the undo stack
        const entry = this.undoStack$.value.at(-1);
        if (entry) {
            // If there is a change, remove it from the undo stack and add it to the redo stack
            this.undoStackSubject$.next(this.undoStack$.value.slice(0, -1));
            this.redoStackSubject$.next([...this.redoStack$.value, entry]);

            // If we're undoing a creation, we want to call to delete the items
            if (entry.operation === "create") {
                // In the absence of strict mode, cast is required here
                const fullItems = this.deleteHandler?.(entry as ExtractRestorableEntry<TEntry>);
                if (fullItems?.length) {
                    updateLatestEntryWithFullItems(this.redoStackSubject$, fullItems);
                }
            }

            // If we're undoing a deletion, we want to call to restore the items
            if (entry.operation === "delete") {
                this.restoreHandler?.(entry as ExtractRestorableEntry<TEntry>);
            }

            // If we're undoing an update, we want to call to update the items but in reverse
            if (entry.operation === "update") {
                // Reverse update
                const updateInReverse = entry.change.reduce<ExtractUpdateEntry<TEntry>>((acc, { from, to }) => ({ ...acc, change: [...acc.change, { from: to, to: from }] }), {
                    ...(entry as ExtractUpdateEntry<TEntry>),
                    change: [],
                });
                this.updateHandler?.(updateInReverse);
            }
        }
        return entry;
    }

    /**
     * Redoes the last change
     * @returns The entry of the change which has been redone
     */
    public redo() {
        // Get the last change in the redo stack
        const entry = this.redoStack$.value.at(-1);
        if (entry) {
            // If there is a change, remove it from the redo stack and add it to the undo stack
            this.redoStackSubject$.next(this.redoStack$.value.slice(0, -1));
            this.undoStackSubject$.next([...this.undoStack$.value, entry]);

            if (entry.operation === "create") {
                // In the absence of strict mode, cast is required here
                this.restoreHandler?.(entry as ExtractRestorableEntry<TEntry>);
            }

            if (entry.operation === "delete") {
                const fullItems = this.deleteHandler?.(entry as ExtractRestorableEntry<TEntry>);
                if (fullItems?.length) {
                    updateLatestEntryWithFullItems(this.undoStackSubject$, fullItems);
                }
            }

            if (entry.operation === "update") {
                this.updateHandler?.(entry as ExtractUpdateEntry<TEntry>);
            }
        }
        return entry;
    }

    /**
     * Filters the undo/redo stacks
     */
    public removeFromStack(reduce: (acc: TEntry[], entry: TEntry) => TEntry[]) {
        this.undoStackSubject$.next(this.undoStack$.value.reduce(reduce, []));
        this.redoStackSubject$.next(this.redoStack$.value.reduce(reduce, []));
    }

    /**
     * Sets the delete handler, which is called when items are deleted as a result of either undo or redo
     * @returns this
     */
    public onDelete(handler: (entry: ExtractRestorableEntry<TEntry>) => ExtractRestorableEntry<TEntry>["items"] | undefined) {
        this.deleteHandler = handler;
        return this;
    }

    /**
     * Sets the restore handler, which is called when items are restored as a result of either undo or redo
     * @returns this
     */
    public onRestore(handler: (entry: ExtractRestorableEntry<TEntry>) => void) {
        this.restoreHandler = handler;
        return this;
    }

    /**
     * Sets the update handler, which is called when items are updated
     * @returns this
     */
    public onUpdate(handler: (entry: ExtractUpdateEntry<TEntry>) => void) {
        this.updateHandler = handler;
        return this;
    }

    /**
     * Clears the undo and redo stacks
     */
    public clear(): void {
        this.undoStackSubject$.next([]);
        this.redoStackSubject$.next([]);
    }
}

/** On the given stack, this function takes the last create/delete entry and updates the items to the given list */
function updateLatestEntryWithFullItems<TItemPartial extends unknown, TItem extends TItemPartial, TEntry extends UndoRedoEntry<TItemPartial, TItem>>(
    stack$: BehaviorSubject<TEntry[]>,
    fullItems: ExtractRestorableEntry<TEntry>["items"]
) {
    const entry = stack$.value.at(-1);
    if (entry?.operation === "create" || entry?.operation === "delete") {
        const updatedEntry: TEntry = {
            ...entry,
            items: entry.items.map((change, index) => fullItems[index] ?? change),
        };
        stack$.next([...stack$.value.slice(0, -1), updatedEntry]);
    }
}
