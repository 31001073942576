export async function exponentialBackoff<TRequest extends { status: number }>(request: () => Promise<TRequest>, stepSizeMs: number, maxAttempts: number) {
    const response = await request();

    // If response is too many request then retry
    if (response.status === 429) {
        for (let attempt = 1; attempt < maxAttempts; attempt += 1) {
            // Calculate new wait time
            const waitTime = stepSizeMs * attempt + Math.random() * 1000;
            await new Promise((resolve) => setTimeout(resolve, waitTime));
            // Attempt to make request again
            const response = await request();
            if (response.status !== 429) {
                return response;
            }
        }
        throw new Error("Max attempts exceeded");
    }

    return response;
}

export async function exponentialBackoffCallback(canRunCallback: () => boolean, callback: () => void, stepSizeMs = 100, maxAttempts = 3) {
    for (let attempt = 0; attempt < maxAttempts; attempt += 1) {
        // Check if we can do the callback
        const response = canRunCallback();
        if (response) {
            callback();
            return;
        }
        // Calculate new wait time
        const waitTime = stepSizeMs * attempt + Math.random() * 1000;
        await new Promise((resolve) => setTimeout(resolve, waitTime));
    }
    callback();
}
