import { useEffect, useMemo, useRef, useState } from "react";
import { BehaviorSubject, Observable, Unsubscribable } from "rxjs";
import { filter, pairwise, startWith } from "rxjs/operators";
import { TupleToUnion } from "@iventis/types/useful.types";

// turns a changing value, e.g. a component property into Observable
// Useful when the property value may change often and it needs in combination with other streamed values
export const useObservable = (value) => {
    const stream$ = useMemo(() => new BehaviorSubject(undefined), []);
    useEffect(() => {
        stream$.next(value);
    }, [value]);

    return stream$;
};

export const useObservableValue = <T>(source$: Pick<Observable<T>, "subscribe"> | undefined, initialValue?: T) => {
    const [value, setValue] = useState<T | undefined>(initialValue);
    useEffect(() => {
        if (source$ === undefined) {
            return undefined;
        }
        const pub = source$.subscribe((nextValue) => {
            setValue(nextValue);
        });
        return () => pub.unsubscribe();
    }, [source$]);

    return value;
};

/** Passes the given callback to the gievm observable and unsubscribes on unmount */
export const useObservableCallback = <T>(source$: Observable<T>, options: { signal?: boolean }, callback: (val: T, signal: AbortSignal) => void) => {
    useEffect(() => {
        const abortController = options.signal ? new AbortController() : undefined;
        const sub = source$.subscribe((v) => callback(v, abortController?.signal));
        return () => {
            abortController?.abort();
            sub.unsubscribe();
        };
    }, []);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useObservableSelectedValue = <TObject extends Record<string, any>, TKeys extends (keyof TObject)[]>(
    source$: Observable<TObject>,
    initialValue: TObject,
    /** If this returns true, it will block the re-render for that particular key */
    blocker: <T>(prev: T, next: T, key: keyof TObject) => boolean,
    ...keys: TKeys
): { [Key in TKeys extends [string, ...string[]] ? TupleToUnion<TKeys> : never]: TObject[Key] } => {
    const [value, setValue] = useState(initialValue);
    useEffect(() => {
        if (source$ === undefined) {
            return undefined;
        }
        const pub = source$
            .pipe(
                startWith(initialValue),
                pairwise(),
                filter(([prev, next]) =>
                    keys.some((key) => {
                        if (blocker(prev, next, key)) {
                            return false;
                        }
                        const isEqual = prev[key] !== next[key];
                        return isEqual;
                    })
                )
            )
            .subscribe(([, nextValue]) => {
                setValue(nextValue);
            });
        return () => pub.unsubscribe();
    }, [source$]);

    return value;
};

export const useDelayedObservableValue = <T>(initialValue?: T) => {
    const [value, setValue] = useState<T | undefined>(initialValue);

    const subscription = useRef<Unsubscribable>();

    const initialised = useRef<boolean>();

    const initialise = (source: Observable<T>) => {
        if (initialised.current) {
            throw new Error("Cannot initialise delayed observable because it has already been initialised. This can indicate a memory leak.");
        }
        initialised.current = true;
        // Enforce the component this is used in to re-render
        subscription.current = source.subscribe((nextValue) => setValue({ ...nextValue }));
    };

    useEffect(
        () => () => {
            subscription.current?.unsubscribe();
            initialised.current = false;
        },
        []
    );

    return { value, initialise };
};
