/* eslint-disable class-methods-use-this */
import { Map } from "mapbox-gl";

export type AttributionPosition = "bottom-right" | "bottom-left" | "top-left" | "top-right";

export abstract class MapboxAttribution {
    public readonly map: Map;

    /** Where the attribution is being placed on the map */
    public readonly attributionPosition: AttributionPosition;

    public attribution: HTMLDivElement;

    constructor(map: Map, attributionPosition: AttributionPosition, containerClassName?: string) {
        this.map = map;
        this.attributionPosition = attributionPosition;
        this.addAttribution(containerClassName);
        this.setInitialAttributionValue();
    }

    public abstract setInitialAttributionValue(): void;

    public abstract onMouseEnter(): void;

    public abstract onMouseLeave(): void;

    public abstract onClick(): void;

    /** Adds attribution to the map */
    public addAttribution(classNames?: string) {
        const container = this.getAttributionContainer();
        const attribution = this.createAttribution(classNames);
        this.attribution = attribution;
        container.appendChild(attribution);
        this.addEventHandlers();
    }

    /** Creates attribution with mapbox classes to ensure styling is consistent */
    public createAttribution(classNames?: string) {
        // Create outer container
        const container = document.createElement("div");
        container.className = `mapboxgl-ctrl mapboxgl-ctrl-attrib ${classNames}`;
        return container;
    }

    /** Gets attribution container from the map */
    private getAttributionContainer(): HTMLDivElement {
        const container = this.map.getContainer().getElementsByClassName(`mapboxgl-ctrl-${this.attributionPosition}`).item(0);

        if (!(container instanceof HTMLDivElement)) {
            throw new Error("Can't find attribution container");
        }

        return container;
    }

    /** Sets a string attribution value */
    public setStringAttributionValue(value: string) {
        const valueContainer = document.createElement("div");
        valueContainer.innerText = value;
        this.setAttributionValue(valueContainer);
    }

    /** Sets an image attribution value */
    public setImageAttributionValue(imageSource: string, imageClassName: string) {
        const image = new Image();
        image.src = imageSource;
        image.className = imageClassName;
        this.setAttributionValue(image);
    }

    public setElementAttribution(element: Node) {
        this.setAttributionValue(element);
    }

    /** Updates or adds the children element to the attribution */
    private setAttributionValue(element: Node) {
        const child = this.attribution.childNodes[0];
        if (child == null) {
            // If child can't be found add to the map
            this.attribution.appendChild(element);
        } else {
            // If child can be found replace it in the map
            this.attribution.replaceChild(element, child);
        }
    }

    /** Event listeners for click, mouseenter and mouseleave */
    private addEventHandlers() {
        this.attribution.addEventListener("click", () => this.onClick());
        this.attribution.addEventListener("mouseenter", () => this.onMouseEnter());
        this.attribution.addEventListener("mouseleave", () => this.onMouseLeave());
    }

    /** Remove the attribution */
    public destroy() {
        this.attribution.remove();
    }
}
