import { OptionalExceptFor } from "@iventis/types/useful.types";
import { ExtractUpdateEntry, UndoRedoEntry, UndoRedoV2 } from "@iventis/utilities/src/undo-redo/undo-redo-v2";
import isEqual from "lodash.isequal";
import { CompositionMapObject } from "../types/internal";
import { extractChangeInGeoJson, PartialFeature, undoableMapObjectProperties } from "./undo-redo-helpers";

/** The partial undoable item for map object changes */
export interface PartialCompositionMapObject extends OptionalExceptFor<Omit<CompositionMapObject, "geojson">, "objectId" | "layerId"> {
    geojson?: PartialFeature;
}

export type CompositionUndoRedoItem = PartialCompositionMapObject;

export type CompositionUndoRedoOperations = {
    updateGeometry: (payload: CompositionMapObject[]) => void;
    /** Cancel composition, called when the last available undo is called */
    cancel: () => void;
};

/** A class which can be used to handle undo/redo while in composition */
export class CompositionUndoRedo {
    private undoRedo: UndoRedoV2<PartialCompositionMapObject>;

    private latestFullObjects: CompositionMapObject[] | undefined;

    constructor(private operations: CompositionUndoRedoOperations) {
        this.undoRedo = new UndoRedoV2<CompositionUndoRedoItem>("composition-undo-redo")
            .onUpdate((entry) => {
                const newFullObjects: CompositionMapObject[] = entry.change.map(({ from, to }) => {
                    const fullObject = this.latestFullObjects?.find((f) => f.objectId === to.objectId);
                    let geometry = to?.geojson?.geometry;
                    // If the change doesn't involve the geometry, we need to keep the geometry from the full object
                    if (geometry == null && from?.geojson?.geometry == null) {
                        geometry = fullObject?.geojson?.geometry;
                    }
                    return {
                        ...fullObject,
                        ...to,
                        geojson: {
                            ...fullObject?.geojson,
                            ...to?.geojson,
                            geometry,
                            properties: {
                                id: fullObject?.objectId,
                                layerid: fullObject?.layerId,
                                ...fullObject?.geojson?.properties,
                                ...to?.geojson?.properties,
                            },
                        },
                    };
                });
                this.operations.updateGeometry(newFullObjects);
                this.latestFullObjects = newFullObjects;
            })
            .onDelete(() => {
                this.clear();
                this.operations.cancel();
                return undefined;
            });
    }

    get instance() {
        return this.undoRedo;
    }

    startComposition(features: CompositionMapObject[]) {
        this.clear();
        this.latestFullObjects = features;
    }

    push(features: CompositionMapObject[]) {
        // If we can't undo, we know it's the first push of this composition, so we set the "from" part of the change to "missing"
        const lastUpdate = this.undoRedo.canUndo ? this.latestFullObjects : undefined;

        // Construct the entry to be pushed
        const entry =
            lastUpdate == null
                ? { operation: "create" as const, items: features }
                : {
                      operation: "update" as const,
                      change: features.map((next) => {
                          const previous = lastUpdate?.find((f) => f.objectId === next.objectId);
                          return extractMapObjectUndoRedoChange(previous, next);
                      }),
                  };
        this.undoRedo.push(entry);
        this.latestFullObjects = features;
    }

    undo() {
        // If we try to undo but there's no undo history, we should cancel drawing since the composition is empty
        if (this.undoRedo.canUndo) {
            this.undoRedo.undo();
        } else {
            this.operations.cancel();
        }
    }

    redo() {
        this.undoRedo.redo();
    }

    clear() {
        this.undoRedo.clear();
        this.latestFullObjects = undefined;
    }

    destroy() {
        this.clear();
    }
}

type MapObjectUndoRedoChange = ExtractUpdateEntry<UndoRedoEntry<CompositionUndoRedoItem>>["change"][number];

/** Given a previous state and next state, records only the things that changed in a composition undo/redo change format */
export function extractMapObjectUndoRedoChange(previous: PartialCompositionMapObject | undefined, next: PartialCompositionMapObject): MapObjectUndoRedoChange | undefined {
    if (!previous || !next) {
        return undefined;
    }
    const change: MapObjectUndoRedoChange = {
        from: { objectId: previous.objectId, layerId: previous.layerId },
        to: { objectId: next.objectId, layerId: next.layerId },
    };
    const changeInGeoJson = extractChangeInGeoJson(previous.geojson, next.geojson, () => undoableMapObjectProperties);
    change.from.geojson = changeInGeoJson.from;
    change.to.geojson = changeInGeoJson.to;
    if (previous.level !== next.level) {
        change.from.level = previous.level;
        change.to.level = next.level;
    }
    if (previous.sitemapId !== next.sitemapId) {
        change.from.sitemapId = previous.sitemapId;
        change.to.sitemapId = next.sitemapId;
    }
    if (!isEqual(previous.waypoints, next.waypoints)) {
        change.from.waypoints = previous.waypoints;
        change.to.waypoints = next.waypoints;
    }

    return change;
}
