/* eslint-disable react/jsx-props-no-spreading */
import { Dialog, DialogProps, SxProps } from "@mui/material";
import React, { PropsWithChildren, useContext, useMemo } from "react";
import { css } from "@emotion/react";
import { styled } from "@iventis/styles";
import { modalMaxHeight, zIndexes } from "@iventis/styles/src/atomic-rules";
import { useWindowSize } from "@iventis/styles/src/use-window-size";
import { screenSizeBreakpoints } from "@iventis/styles/src/screen-sizes";
import { ErrorBoundaryContext, IventisFallbackProps, LargeFallback } from "@iventis/error-boundaries";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { errorToErrorCode } from "@iventis/error-boundaries/src/error-code";
import { SentryErrorBoundary, SentryFallbackRender } from "@iventis/observability-and-monitoring/src/error-boundary/sentry-error-boundary";

export enum CustomDialogAnchor {
    TOP = "TOP",
    CENTER = "CENTER",
}

export interface CustomDialogProps extends DialogProps {
    mainContainer?: string;
    anchor?: CustomDialogAnchor;
    stopPropagation?: boolean;
}

export const CustomDialog: React.FC<CustomDialogProps> = ({ mainContainer = "main", open, onClose, anchor = CustomDialogAnchor.CENTER, stopPropagation, children, ...props }) => {
    const { screenWidth } = useWindowSize();
    const fullScreen = useMemo(() => screenWidth < screenSizeBreakpoints.extraSmall, [screenWidth]);

    const paperProps: SxProps = anchor === CustomDialogAnchor.TOP && !fullScreen ? { position: "absolute", top: 50, m: 0 } : {};

    return (
        <DialogWithIndex
            fullScreen={fullScreen}
            open={open}
            // eslint-disable-next-line react/destructuring-assignment
            slotProps={{ backdrop: { style: { position: "inherit" }, ...props?.slotProps?.backdrop } }}
            PaperProps={{ sx: paperProps, onClick: (e) => stopPropagation && e.stopPropagation() }}
            container={() => document.getElementById(mainContainer)}
            onClose={(event: React.MouseEvent<unknown, MouseEvent>, reason) => {
                onClose(event, reason);
                if (stopPropagation) {
                    event.stopPropagation();
                }
            }}
            // Ensure events don't bubble up to drag and drop
            onPointerDown={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
            {...props}
        >
            <ModalErrorBoundary onClose={() => onClose(null, null)}>{children}</ModalErrorBoundary>
        </DialogWithIndex>
    );
};

const DialogWithIndex = styled(Dialog)`
    position: absolute;
    z-index: ${zIndexes.dialog};
`;

export const StyledCustomDialog = styled(CustomDialog)<{
    $noPadding?: boolean;
    $alternativePadding?: boolean;
    $staticHeight?: string;
    $screenWidth?: number;
    $minWidth?: string;
    $overflow?: string;
    $trackColour?: string;
    $fullHeight?: boolean;
}>`
    .MuiDialog-paperWidthSm {
        min-width: ${(props) => (props.$screenWidth > screenSizeBreakpoints.extraSmall ? (props.$minWidth != null ? props.$minWidth : "50vw") : "100%")};
        height: ${(props) => (props.$screenWidth > screenSizeBreakpoints.extraSmall && props.$staticHeight ? props.$staticHeight : undefined)};
        ${(props) =>
            props.$fullHeight && props.$screenWidth > screenSizeBreakpoints.extraSmall
                ? css`
                      min-height: ${modalMaxHeight};
                      top: 32px;
                  `
                : undefined};
        box-sizing: border-box;
    }

    .MuiPaper-root {
        max-width: 910px;
    }

    .MuiDialogContent-root {
        /* Overrides an overflow: initial that is being pushed presumably by MUI */
        overflow-y: ${(props) => props.$overflow ?? "auto"} !important;
        scrollbar-color: inherit ${(props) => props.$trackColour ?? props.theme.shades.four} !important; /* Two valid colors. */

        /* Track */
        ::-webkit-scrollbar-track {
            background: ${(props) => props.$trackColour ?? props.theme.shades.four} !important;
        }
    }

    ${(props) =>
        props.$noPadding &&
        `
    .MuiDialogContent-root {
        padding: 0;
    }
    `}

    ${(props) =>
        props.$alternativePadding &&
        `
    .MuiDialogContent-root {
        padding: 0 18px;
    }
    `}
`;

// Dialog Skeleton
interface ModalSkeletonProps extends IventisFallbackProps {
    onClose: () => void;
}
const ModalErrorBoundary: React.FC<PropsWithChildren<{ onClose: () => void }>> = ({ children, onClose }) => {
    const errorContext = useContext(ErrorBoundaryContext);

    const onError: SentryFallbackRender = ({ error, resetError }) => {
        errorContext.onError(errorToErrorCode(error as Error), error as Error);
        return <ModalSkeleton onClose={onClose} error={error} resetErrorBoundary={resetError} />;
    };

    return <SentryErrorBoundary fallback={onError}>{children}</SentryErrorBoundary>;
};

const ModalSkeleton: React.FC<ModalSkeletonProps> = ({ error, resetErrorBoundary, onClose }) => {
    const translate = useIventisTranslate();
    return (
        <div>
            <StyledTopDiv>
                <SubHeaderSkeleton />
                <HeaderSkeleton />
            </StyledTopDiv>
            <StyledFallback error={error} action={{ action: onClose, actionText: translate(Content.common.buttons.close) }} resetErrorBoundary={resetErrorBoundary} />
            <StyledBottomDiv>
                <ButtonDiv>
                    <ButtonSkeleton /> <ButtonSkeleton />
                </ButtonDiv>
            </StyledBottomDiv>
        </div>
    );
};

const StyledFallback = styled(LargeFallback)`
    width: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const StyledTopDiv = styled.div`
    height: 20px;
    margin: 20px;
`;
const SubHeaderSkeleton = styled.div`
    height: 5px;
    width: 100px;
    background-color: #b6b6b6;
    margin-bottom: 15px;
`;

const HeaderSkeleton = styled.div`
    height: 5px;
    width: 150px;
    background-color: #b6b6b6;
`;

const StyledBottomDiv = styled.div`
    height: 44px;
    margin: 16px 20px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
`;

const ButtonSkeleton = styled.div`
    width: 140px;
    height: 44px;
    margin-right: 10px;
    background-color: #d9d9d9;
`;

const ButtonDiv = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: flex-end;
`;
