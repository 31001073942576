/* eslint-disable class-methods-use-this */
import { Map } from "mapbox-gl";
import { AttributionPosition, MapboxAttribution } from "./engine-mapbox-attribution";
import * as google from "../../../assets/google.png";
import "./engine-mapbox-google-logo-attribution.css";

export class GoogleLogoMapboxAttribution extends MapboxAttribution {
    constructor(map: Map, attributionPosition: AttributionPosition) {
        super(map, attributionPosition);
        this.setInitialAttributionValue();
    }

    public createAttribution() {
        const container = super.createAttribution();
        // Make the container the same height as the image
        container.setAttribute("style", "display: flex; align-items: center; padding-left: 5px;");
        container.setAttribute("data-testid", "google-maps-attribution");
        return container;
    }

    public setInitialAttributionValue() {
        this.setImageAttributionValue(google.default, "google-logo");
    }

    public onMouseEnter() {
        // Do nothing
    }

    public onMouseLeave() {
        // Do nothing
    }

    public onClick() {
        // Do nothing
    }
}
